import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

import FileUploader from '@components/UI/FileUploader';
import InputCustom from '@components/UI/InputCustom';
import congesService from '@data/congesService';
import AlertModal from './AlertModal';
import Spinner from '@components/UI/Spinner';
import { isObjEmpty, arrayBetweenTwoDates } from '@utility/Utils';

import { ButtonFirstAction } from '@components/UI/Buttons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from 'gatsby';

import {
  faArrowLeft,
  faExclamationTriangle,
  faSignalBars,
} from '@fortawesome/pro-regular-svg-icons';

import dayjs from 'dayjs';

import ToastCustom from '@components/UI/ToastCustom';
import SwalCustom from '@helpers/SwalCustom';

import UserContext from '@context/UserContext';
import CounterSlideOver from '@components/Request/CounterSlideOver';
import { Tooltip } from '@mui/material';
import { getFullname } from '@utility/Utils';

const Request = () => {
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [userCounters, setUserCounters] = useState([]);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [overLeaves, setOverLeaves] = useState();
  const [client, setClient] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [whitDays, setWhitDays] = useState([]);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const currentDay = dayjs();
  const currentDayPlusOneYear = currentDay.add(1, 'year');
  const { t } = useTranslation();

  const { currentUser } = useContext(UserContext);

  const getClient = async (signal) => {
    congesService
      .get('/v1/clients/current', { signal })
      .then((res) => {
        setClient(res.data);
      })
      .catch((err) => console.error(err));
  };

  const getWhitDays = async (signal) => {
    const res = await congesService.get(
      `/v1/leaves/public-holidays?startDate=${dayjs().format(
        'YYYY-MM-DD'
      )}&endDate=${dayjs().add(1, 'year').format('YYYY-MM-DD')}`,
      { signal }
    );
    setWhitDays(
      res.data.data.map((r) => {
        return {
          ...r,
          date: dayjs(r.date),
        };
      })
    );
  };

  const fetchUsersCounter = async () => {
    try {
      const response = await congesService.post(
        '/v1/user-leaves-count/search?include=leave_type,futureLeaves',
        {
          filters: [
            {
              field: 'user_id',
              operator: '=',
              value: currentUser.id,
            },
          ],
        }
      );
      setUserCounters(
        response.data.data
          .map((element) => {
            return {
              ...element,
              balance:
                Math.round(
                  (element.acquired - element.futureLeaves - element.taken) *
                    100
                ) / 100,
            };
          })
          .sort(
            (counters) =>
              counters.order_appeareance < counters.order_appeareance
          )
      );
    } catch (err) {
      console.error(err);
    }
  };

  const getLeaveTypes = async (signal) => {
    const response = await congesService.post(
      '/v1/leave-types/search?include=leave_type_sub_families',
      {
        filters: [
          {
            field: 'is_active',
            operator: '=',
            value: true,
          },
        ],
      },
      { signal }
    );
    setLeaveTypes(response.data.data);
  };

  const initData = async ({ signal }) => {
    try {
      await getLeaveTypes(signal);
      await getWhitDays(signal);
      getClient(signal);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUsersCounter();
    const abortController = new AbortController();
    const signal = abortController.signal;

    initData({ signal });
    return () => {
      abortController.abort();
    };
  }, []);

  const handleConfirm = (values, actions) => {
    submitData(values, actions);
    setShowAlertModal(false);
  };

  const handleCancel = (props) => {
    props.setSubmitting(false);
    setShowAlertModal(false);
  };

  const handleSubmitRequest = (values, props) => {
    let showAlert = false;
    for (const counter of userCounters) {
      if (
        counter.leave_type_id === values.leaves_types.id &&
        counter.is_last_year === true &&
        counter.balance < values.duration
      ) {
        setOverLeaves(values.duration - counter.balance);
        setShowAlertModal(true);
        showAlert = true;
        break;
      }
    }
    if (!showAlert) {
      submitData(values, props);
    }
  };

  const submitData = (values, props) => {
    if (!values.duration) {
      ToastCustom.error(t('durationMustBeGreaterThanZero'));
      return;
    }

    // if morging is checked then set start date to 12:00
    const startDate = values.startDateMorning
      ? dayjs(values.dates[0])
          .set('hour', 0)
          .set('hour', 0)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DDTHH:mm:ss')
      : dayjs(values.dates[0])
          .set('hour', 0)
          .set('hour', 12)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DDTHH:mm:ss');

    // check if start date is equal to end date
    if (dayjs(values.dates[0]).isSame(values.dates[1])) {
      values.dates[1] = dayjs(values.dates[1]).endOf('day');
    }

    // if afternoon is checked then set end date to 12:00
    const endDate = values.endDateMorning
      ? dayjs(values.dates[1])
          .set('hour', 0)
          .set('hour', 12)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DDTHH:mm:ss')
      : dayjs(values.dates[1])
          .set('hour', 0)
          .set('hour', 23)
          .set('minute', 59)
          .set('second', 0)
          .format('YYYY-MM-DDTHH:mm:ss');

    // build formData
    const formData = new FormData();

    const data = JSON.stringify({
      leave_type_id: values.leaves_types.id,
      leave_type_sub_family_id: isObjEmpty(values?.leave_type_sub_families)
        ? null
        : values.leave_type_sub_families.id,
      start_date: startDate,
      end_date: endDate,
      user_id: values.user_id.id,
      duration: values.duration,
      comment: values.comments,
    });

    // build formData
    formData.append('data', data);
    values.files?.length > 0 && formData.append('attachment', values.files[0]);

    congesService
      .post('/v1/leaves', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => {
        ToastCustom.validated(t(`${data.message}`));
        navigate(-1);
      })
      .catch((err) => {
        console.error(err.response);
        const error = err.response?.data?.message;
        ToastCustom.error(t(error ? error : t('createError')));
      })
      .finally(() => {
        props.setSubmitting(false);
      });
  };

  const loadOptions = async (inputValue) => {
    try {
      const response = await congesService.post(
        `/v1/users/search?include=user_leave_counts.leave_type,days&page=${
          inputValue ? 1 : page
        }`,
        {
          search: {
            value: `%${inputValue}%`,
          },
          sort: [
            {
              field: 'lastname',
              direction: 'asc',
            },
          ],
        }
      );
      const res = response.data;
      setPage(
        res.meta.current_page < res.meta.last_page
          ? res.meta.current_page + 1
          : 1
      );
      return {
        options:
          currentUser?.profile.label === 'STANDARD'
            ? []
            : res.data.length > 0
            ? res.data.map((user) => ({
                ...user,
                name: getFullname(user),
              }))
            : [],
        hasMore: page !== res.meta.last_page,
      };
    } catch (err) {
      console.error(err);
    }
  };

  const disabledOpenDays = (date) => {
    let openDays = [];
    if (
      client?.is_allowed_to_modify_open_days &&
      currentUser.days?.length > 0
    ) {
      openDays = currentUser.days;
    } else {
      openDays = client.open_days;
    }
    let formattedDate;
    if (parseInt(dayjs(date).format('d')) === 0) {
      formattedDate = 7;
    } else {
      formattedDate = parseInt(dayjs(date).format('d'));
    }

    return !openDays.includes(formattedDate);
  };

  const shouldDisableDate = (date) => {
    //is there a whit day the day of the date
    if (
      whitDays.some((d) => dayjs(d.date).isSame(dayjs(date), 'day')) &&
      client?.count_public_holidays
    ) {
      return true;
    } else if (disabledOpenDays(date)) {
      return true;
    } else if (date > currentDayPlusOneYear) {
      return true;
    } else {
      return false;
    }
  };

  const calculeOpenDays = (props) => {
    let count = 1;

    const startDate = dayjs(props.values.dates[0]);
    const endDate = dayjs(props.values.dates[1]);

    //array of days between two dates
    const arrayDates = arrayBetweenTwoDates(
      dayjs(startDate).format('YYYY-MM-DD'),
      dayjs(endDate).format('YYYY-MM-DD')
    );

    count = arrayDates?.length;

    // Decrement count if day is not a working day
    arrayDates.forEach(({ date }) => {
      disabledOpenDays(date) && count--;
    });

    // Decrement count if the client allow public whit day and if the day is not pentecost
    if (client?.count_public_holidays) {
      const isWhitDaysInRange = whitDays.filter(({ date }) => {
        const publicHolidaysDate = dayjs(date);
        const isWeekend =
          publicHolidaysDate.day() === 0 || publicHolidaysDate.day() === 6;
        const isInRange = publicHolidaysDate.isBetween(
          startDate,
          endDate,
          'day',
          '[]'
        );

        return isInRange && !isWeekend;
      });
      //check if the whit day is not a working day
      if (isWhitDaysInRange.length) {
        count -= isWhitDaysInRange.length;
      }
    }

    // Decrement count if the startDate or endDate is a half day
    if (!props.values.startDateMorning) {
      count -= 0.5;
    }
    if (props.values.endDateMorning) {
      count -= 0.5;
    }

    // Calcul to know the number of overruns
    if (
      props.values.leaves_types?.can_exceed &&
      props.values.leaves_types?.is_monthly
    ) {
      // Concat n-1 + n
      let overruns = props.values.user_id?.user_leave_counts
        ?.filter(
          (counter) => counter.leave_type?.id === props.values.leaves_types?.id
        )
        .reduce((overruns, b) => overruns + b.balance, 0);
      if (count > overruns) {
        overruns = count - overruns;
      } else {
        overruns = 0;
      }
      props.setFieldValue('overruns', overruns);
    }

    props.setFieldValue('duration', count);
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <div className='bg-white p-12'>
        <div className='w-[520px] mx-auto rounded-lg'>
          <div className='w-full relative mb-10'>
            <FontAwesomeIcon
              icon={faArrowLeft}
              className='absolute left-0 mt-[6px] cursor-pointer'
              onClick={() => navigate(-1)}
            />
            <h2 className=' font-bold text-center text-secondarylight'>
              {t('newLeave')}
            </h2>
          </div>
          <Formik
            initialValues={{
              leaves_types:
                leaveTypes.find((type) => type.id === 4) ?? leaveTypes[0],
              user_id: {
                ...currentUser,
                name: getFullname(currentUser),
              },
              leave_type_sub_families: {},
              startDateMorning: true,
              endDateMorning: false,
              dates: [null, null],
              duration: 0,
              comments: '',
              files: [],
              overruns: 0,
            }}
            onSubmit={(values, actions) => {
              if (
                values.leaves_types.can_justify_later &&
                !values.files?.length
              ) {
                SwalCustom.info(() => handleSubmitRequest(values, actions), {
                  title: '',
                  text: 'justificatifMessage',
                });
                actions.setSubmitting(false);
              } else {
                handleSubmitRequest(values, actions);
              }
            }}
            validate={(values) => {
              const errors = {};
              if (!values.dates[0] || !values.dates[1]) {
                errors.dates = t('requiredField');
              }
              if (isObjEmpty(values.leaves_types)) {
                errors.leaves_types = t('requiredField');
              }
              return errors;
            }}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {(props) => (
              <>
                {showAlertModal && (
                  <AlertModal
                    onCancel={() => handleCancel(props)}
                    onConfirm={() => handleConfirm(props.values, props)}
                    duration={overLeaves}
                  />
                )}
                <Form className='flex flex-col gap-y-6'>
                  {currentUser?.profile?.label !== 'STANDARD' && (
                    <InputCustom
                      type='asyncPaginate'
                      label={t('collaborator') + ' *'}
                      loadOptions={loadOptions}
                      value={props.values.user_id}
                      isMulti={false}
                      name='user_id'
                      additional={{
                        page: page,
                      }}
                      id='user_id'
                    />
                  )}
                  <InputCustom
                    type='reactSelect'
                    label={t('leaveType') + ' *'}
                    onChange={(value) => {
                      props.setFieldValue('leaves_types', value);
                      props.setFieldValue(
                        'leave_type_sub_families',
                        !isObjEmpty(value.leave_type_sub_families)
                          ? value.leave_type_sub_families[0]
                          : {}
                      );
                      props.setFieldValue('dates', ['', '']);
                      props.setFieldValue('duration', 0);
                      props.setFieldValue('overruns', 0);
                    }}
                    options={leaveTypes}
                    name='leaves_types'
                    value={props.values.leaves_types}
                    id='leaves_types'
                  />
                  {props.values.leaves_types?.leave_type_sub_families?.length >
                    0 && (
                    <InputCustom
                      type='reactSelect'
                      label={t('leaveTypeSubFamily') + ' *'}
                      options={
                        props.values.leaves_types?.leave_type_sub_families
                      }
                      name='leave_type_sub_families'
                      id='leave_type_sub_families'
                    />
                  )}
                  <InputCustom
                    type='dateRangePicker'
                    name='dates'
                    id='dates'
                    shouldDisableDate={shouldDisableDate}
                    placeholder={'selectDate'}
                    label={t('date') + ' *'}
                    isAbsentees={
                      props.values.leaves_types?.is_only_visible_by_admin
                    }
                    onChange={(value) => {
                      if (value[0] !== null && value[1] !== null) {
                        props.setErrors({ dates: undefined });
                        calculeOpenDays({
                          ...props,
                          values: {
                            ...props.values,
                            dates: value,
                          },
                        });
                      } else {
                        props.setErrors({ dates: t('requiredField') });
                      }
                    }}
                  />

                  {props.values.leaves_types?.is_half_day ? (
                    <div className='flex gap-x-16'>
                      <div>
                        <label className='block text-sm font-medium text-gray-700'>
                          {!props.values.dates[0]
                            ? t('startDate')
                            : t('from').toLowerCase() +
                              ' ' +
                              dayjs(props.values.dates[0]).format('DD/MM/YYYY')}
                        </label>
                        <InputCustom
                          containerClass='mt-2'
                          type='radio'
                          name='startDateMorning'
                          value={props.values.startDateMorning}
                          id='startDateMorning'
                          disabled={!props.values.dates[0]}
                          onChange={(value) => {
                            calculeOpenDays({
                              ...props,
                              values: {
                                ...props.values,
                                startDateMorning: value,
                              },
                            });
                          }}
                          firstLabel={t('morning')}
                          secondLabel={t('afternoon')}
                        />
                      </div>
                      {dayjs(props.values.dates[1]).isValid() && (
                        <div>
                          <label className='block text-sm font-medium text-gray-700'>
                            {!props.values.dates[0]
                              ? t('endDate')
                              : t('to') +
                                ' ' +
                                dayjs(props.values.dates[1]).format(
                                  'DD/MM/YYYY'
                                )}
                          </label>
                          <InputCustom
                            name='endDateMorning'
                            id='endDateMorning'
                            disabled={!props.values.dates[1]}
                            containerClass='mt-2'
                            type='radio'
                            value={props.values.endDateMorning}
                            onChange={(value) => {
                              calculeOpenDays({
                                ...props,
                                values: {
                                  ...props.values,
                                  endDateMorning: value,
                                },
                              });
                            }}
                            firstLabel={t('morning')}
                            secondLabel={t('afternoon')}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                  <div className='flex items-center gap-x-2'>
                    {props.values.overruns ? (
                      <Tooltip
                        title={
                          <div className='flex items-center gap-x-4'>
                            <p className='font-medium text-sm'>
                              {t('isExceeding')}
                              <span className='text-primary1 ml-1'>
                                {props.values.overruns}
                              </span>
                            </p>
                          </div>
                        }
                        placement='bottom'
                        arrow
                      >
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className='w-5 h-5 text-secondarylight'
                        />
                      </Tooltip>
                    ) : (
                      ''
                    )}
                    <p className='font-semibold text-secondarylight'>
                      {t('openDays') + ' : '}
                      <span className='text-primary1 text-lg'>
                        {props.values.duration}
                      </span>
                    </p>
                  </div>
                  {props.values.leaves_types?.is_attachment_required ? (
                    <FileUploader
                      type='fileUploader'
                      required={'.pdf, .jpeg, .jpg, .png'}
                      handleRemoveFile={() => props.setFieldValue('files', [])}
                      files={props.values.files}
                      setFiles={(e) => props.setFieldValue('files', e)}
                      name='files'
                      isMultiple={false}
                    />
                  ) : (
                    ''
                  )}
                  <InputCustom
                    type='textarea'
                    name='comments'
                    id='textRes'
                    placeholder={t('enterYourMessage')}
                    label={t('comments')}
                  />

                  <div className='flex w-full justify-between mt-[16px]'>
                    <button
                      type='button'
                      onClick={() => setIsOpen(true)}
                      className='flex items-center bg-white text-primary1 ring-2 ring-primary1 box-border rounded-[30px] px-9  hover:bg-lightgrey-900 hover:text-white hover:ring-lightgrey-900 active:bg-lightgrey-500 transition-all duration-200'
                    >
                      <FontAwesomeIcon icon={faSignalBars} />
                      <span className='ml-3 text-sm font-medium'>
                        {t('displayMyLeaves').toUpperCase()}
                      </span>
                    </button>
                    <ButtonFirstAction
                      type='submit'
                      disabled={props.isSubmitting}
                    >
                      {t('send')}
                    </ButtonFirstAction>
                  </div>
                </Form>
                {
                  <CounterSlideOver
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    values={props.values.user_id}
                  />
                }
              </>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default Request;
